
























import Vue from 'vue'

export type SplitTimeDTO = {
  days: string
  hours: string
  minutes: string
  seconds: string
}

export default Vue.extend({
  name: 'Timer',
  props: {
    timeLeft: {
      type: Number,
      default: 0,
    },
  },
  data: function (): {
    time: number
  } {
    return {
      time: this.timeLeft,
    }
  },
  computed: {
    splitTime(): SplitTimeDTO {
      const days = Math.floor(this.time / (1000 * 60 * 60 * 24))
      const hours = Math.floor((this.time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((this.time % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((this.time % (1000 * 60)) / 1000)

      return {
        days: this.formatNumber(days.toString()),
        hours: this.formatNumber(hours.toString()),
        minutes: this.formatNumber(minutes.toString()),
        seconds: this.formatNumber(seconds.toString()),
      }
    },
  },
  methods: {
    formatNumber(number: string): string {
      return number.length === 1 ? `0${number}` : number
    },
  },
  mounted() {
    setInterval(() => {
      this.time = this.time - 1000
      if (this.time < 0) {
        this.$emit('time-over')
      }
    }, 1000)
  },
})
